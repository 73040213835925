import { IPayoutMethod } from "@smartrr/shared/entities/PayoutMethod";
import React, { useCallback } from "react";

import { openStripeConnectWindow } from "./utils";

export interface NewConnectionProps {
  orgId: string;
  myShopifyDomain: string;
  onConnect: (token: IPayoutMethod) => void;
  onError: (errorMessage: string) => void;
}

export function NewConnection({ orgId, myShopifyDomain, onConnect, onError }: NewConnectionProps): JSX.Element {
  const onOpenStripeConnect = useCallback(() => {
    openStripeConnectWindow(myShopifyDomain).then(
      (res: any) => onConnect(res),
      error => onError(error)
    );
    return false;
  }, [orgId, onConnect, onError]);

  return (
    <button className="Polaris-Button" style={{ marginTop: "20px" }} onClick={onOpenStripeConnect}>
      <span className="Polaris-Button__Content">
        <span className="Polaris-Button__Text">Connect with Stripe</span>
      </span>
    </button>
  );
}
