import { appPrefixedHost, vendorApiRoutePrefix } from "@smartrr/shared/constants";
import config from "@vendor-app/config";

import { StripeConnectOAuthParams, StripeOAuthTokenResponse } from "./api";

const STRIPE_OAUTH_AUTHORIZE_URL = "https://connect.stripe.com/oauth/authorize";

const buildStripeAuthorizeUrl = (params: StripeConnectOAuthParams) => {
  const qs = Object.entries(params)
    .map(([key, value]) => key + "=" + encodeURIComponent(value))
    .join("&");
  return [STRIPE_OAUTH_AUTHORIZE_URL, qs].join("?");
};

export const stripeConnectUrl = (myShopifyDomain: string) =>
  buildStripeAuthorizeUrl({
    stripe_landing: "login",
    response_type: "code",
    client_id: config.stripe.oauthClientId,
    scope: "read_only",
    redirect_uri: `https://${appPrefixedHost}${vendorApiRoutePrefix}/integrations/stripe/authorize`,
    state: myShopifyDomain,
  });

/**
 * Opens a Stripe Connect window for authorization.
 */
export const openStripeConnectWindow = async (myShopifyDomain: string) =>
  new Promise<StripeOAuthTokenResponse>(resolve => {
    const oauthWindow = window.open(stripeConnectUrl(myShopifyDomain), "_blank", "toolbar=no, menubar=no");
    if (oauthWindow) {
      const messageEventListener = (event: MessageEvent<StripeOAuthTokenResponse>) => {
        resolve(event.data);
        oauthWindow.close();
        window.removeEventListener("message", messageEventListener);
      };
      window.addEventListener("message", messageEventListener);
    }
  });
