import { IPayoutMethod } from "@smartrr/shared/entities/PayoutMethod";
import React, { useCallback } from "react";

export interface ExistingConnectionProps {
  orgId: string;
  connectedStripePayoutMethod: IPayoutMethod;
  onRemove: VoidFunction;
}
export function ExistingConnection({
  orgId,
  connectedStripePayoutMethod,
  onRemove,
}: ExistingConnectionProps): JSX.Element {
  const onRemoveClick = useCallback(() => {
    onRemove();
    return false;
  }, [orgId, onRemove]);
  return (
    <button
      className={`stripe-connect ${connectedStripePayoutMethod.isTestPayoutMethod ? "live" : "test"}`}
      onClick={onRemoveClick}
    >
      <span>Disconnect Stripe</span>
    </button>
  );
}
