import React, { useCallback, useEffect } from "react";

import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { loadStripeConnect, removeStripeConnect } from "./actions";
import { ExistingConnection } from "./ExistingConnection";
import { LoadingConnection } from "./LoadingConnection";
import { NewConnection } from "./NewConnection";

export default function StripeConnect(): JSX.Element {
  const vendorOrganizations = useSmartrrVendorSelector(state => state.vendorOrganizations);
  const stripeConnect = useSmartrrVendorSelector(state => state.stripeConnect);
  const orgId = vendorOrganizations.activeOrganizationId;
  const myShopifyDomain = useMyShopifyDomainSelector();
  const dispatch = useSmartrrVendorDispatch();

  useEffect(() => {
    dispatch(loadStripeConnect());
  }, [dispatch]);

  const onConnect = useCallback(
    connectedStripePayoutMethod => {
      dispatch({
        type: "LOADED_STRIPE_CONNECT",
        payload: { connectedStripePayoutMethod },
      });
    },
    [dispatch]
  );

  const onError = useCallback(
    errorMessage => {
      dispatch({
        type: "ERROR_CONNECTING_STRIPE_ACCOUNT",
        payload: {
          errorMessage,
        },
      });
    },
    [dispatch]
  );

  const onRemove = useCallback(async () => {
    dispatch(removeStripeConnect());
  }, [dispatch]);

  if (!orgId || !myShopifyDomain) {
    return <div>Loading...</div>;
  }

  return (
    <div className="stripe">
      {!!stripeConnect.isLoading && <LoadingConnection />}
      {!stripeConnect.isLoading && !stripeConnect.connectedStripePayoutMethod && (
        <NewConnection orgId={orgId} myShopifyDomain={myShopifyDomain} onConnect={onConnect} onError={onError} />
      )}
      {!stripeConnect.isLoading && !!stripeConnect.connectedStripePayoutMethod && (
        <ExistingConnection
          orgId={orgId}
          onRemove={onRemove}
          connectedStripePayoutMethod={stripeConnect.connectedStripePayoutMethod}
        />
      )}
      {!!stripeConnect.errorMessage && <p className="stripe-error">{stripeConnect.errorMessage}</p>}
    </div>
  );
}
