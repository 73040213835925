import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const loadStripeConnect =
  (): SmartrrThunkAction<
    "LOADING_STRIPE_CONNECT" | "LOADED_STRIPE_CONNECT" | "ERROR_CONNECTING_STRIPE_ACCOUNT"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/integrations/stripe"),
      () =>
        dispatch({
          type: "LOADING_STRIPE_CONNECT",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_CONNECTING_STRIPE_ACCOUNT",
          payload: {
            errorMessage: failure.message,
          },
        }),
      connectedStripePayoutMethod =>
        dispatch({
          type: "LOADED_STRIPE_CONNECT",
          payload: { connectedStripePayoutMethod },
        })
    );

export const removeStripeConnect =
  (): SmartrrThunkAction<
    "REMOVING_STRIPE_CONNECT" | "REMOVED_STRIPE_CONNECT" | "ERROR_CONNECTING_STRIPE_ACCOUNT"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/integrations/stripe/deauthorize"),
      () =>
        dispatch({
          type: "REMOVING_STRIPE_CONNECT",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_CONNECTING_STRIPE_ACCOUNT",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "REMOVED_STRIPE_CONNECT",
          payload: undefined,
        })
    );
